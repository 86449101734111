import styled from "styled-components";

const InputWrapper = styled.div`
  position: relative;
  padding-top: 1rem;
  margin-top: 0.5rem;
  width: ${(props) => props.width || "100%"};
  border-bottom: 1px solid black;
`;

const InputLabel = styled.label`
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: gray;
  pointer-events: none;
  font-weight: 600;
  user-select: none;
`;

const Indicator = styled.div`
  display: none;
  position: absolute;
  right: 0;
  top: 1.75rem;
  color: gray;
  pointer-events: none;
  user-select: none;
`;

const InputField = styled.input`
  width: 100%;
  border: 0;
  outline: 0;
  font-size: 1.25rem;
  color: black;
  padding: 0.5rem 0 0.25rem;
  background: transparent;
  transition: border-color 0.2s;
  font-weight: 600;
  &::placeholder {
    color: transparent;
    user-select: none;
  }
  &:focus {
    font-weight: 600;
    & ~ ${InputLabel} {
      top: 0;
      font-size: 1rem;
      color: var(--primary);
      font-weight: 600;
    }
    & ~ ${InputWrapper} {
      border-bottom: 2px solid red;
    }
    & ~ ${Indicator} {
      display: block;
    }
  }
  &:placeholder-shown ~ ${InputLabel} {
    font-size: 1.25rem;
    cursor: text;
    top: 1.5rem;
    font-weight: 400;
  }

  &:required,
  &:invalid {
    box-shadow: none;
  }
`;

const DynamicInput = (props) => {
  return (
    <InputWrapper width={props.width}>
      <InputField placeholder="placeholder" />
      <InputLabel>{props.content.name}</InputLabel>
      <Indicator>{props.content.isNum ? "123" : "가/Aa"}</Indicator>
    </InputWrapper>
  );
};

export default DynamicInput;
