import Root from "./routes/Root";
import Error from "./error";
import Request from "./routes/Request";
import Landing from "./routes/Landing";
import { createBrowserRouter } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <Error />,
    children: [
      {
        path: "",
        element: <Landing />,
      },
      {
        path: "request",
        element: <Request />,
      },
    ],
  },
]);

export default router;
