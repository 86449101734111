import styled from "styled-components";
import { Link } from "react-router-dom";

const BG = styled.div`
  width: calc(100vw - 4rem);
  height: 4rem;
  display: grid;
  background-color: var(--primary);
  color: white;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem 1.5rem;
  padding: 0 2rem;
  align-items: center;
  justify-items: start;
  grid-template-areas: "title title . . . . . . others others others others";
  user-select: none;
`;

const Title1 = styled.p`
  color: white;
  transition: 0.2s ease-out;
`;

const Title2 = styled.p`
  color: var(--highlight);
  transition: 0.2s ease-out;
`;

const Title3 = styled.p`
  color: var(--highlight);
  font-size: 1.5rem;
  margin-top: -0.5rem;
  transition: 0.2s ease-out;
`;

const TitleWrapper = styled(Link)`
  grid-area: title;
  font-family: "Comfortaa", cursive;
  margin-top: 0.25rem;
  font-size: 1.75rem;
  cursor: auto;
  display: flex;
  align-items: flex-start;
  font-weight: 600;

  &:hover ${Title1} {
    color: var(--highlight);
  }

  &:hover ${Title2} {
    color: white;
  }

  &:hover ${Title3} {
    color: white;
  }
`;

const Others = styled.div`
  grid-area: others;
  font-family: "Comfortaa", cursive;
  color: white;
  transition: 0.2s ease-out;
  justify-self: end;
`;

const Other = styled.div`
  margin-top: 0.125rem;
  display: inline-block;
  margin-left: 1.5rem;
  transition: 0.2s ease-out;

  &:hover {
    color: var(--highlight);
  }
`;
const RequestLink = styled(Link)`
  margin-top: 0.125rem;
  font-family: "Comfortaa", cursive;
  transition: 0.2s ease-out;
  &:hover {
    color: var(--highlight);
  }
`;

const Nav = () => {
  return (
    <BG>
      <TitleWrapper to={"/"}>
        <Title1>Cel</Title1>
        <Title2>in</Title2>
        <Title1>us</Title1>
        <Title3>+</Title3>
      </TitleWrapper>
      <RequestLink to={"/request"}>REQUEST</RequestLink>
      <Others>
        <Other>
          <a href={`${process.env.PUBLIC_URL}/example.html`}>EXAMPLE</a>
        </Other>
        <Other>
          <a href={`https://www.kr-geninus.com:9008/`}>WEBSITE</a>
        </Other>
        <Other>LOGIN</Other>
      </Others>
    </BG>
  );
};

export default Nav;
