import { Outlet } from "react-router-dom";
import styled from "styled-components";
import Nav from "./NavigationBar";

const BG = styled.div`
  height: 100vh;
  width: 100vw;
`;

const Wrapper12 = styled.div`
  width: calc(100vw - 4rem);
  background-color: var(--secondary);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem 1.5rem;
  padding: 0 2rem 0 2rem;
`;

const Div12 = styled.div`
  height: 1rem;
  background-color: var(--background);
  color: black;
`;
const Root = () => {
  return (
    <BG>
      <Wrapper12>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((el) => (
          <Div12 key={el}>{el}</Div12>
        ))}
      </Wrapper12>
      <Nav />
      <Outlet />
    </BG>
  );
};

export default Root;
