import { useState } from "react";
import styled from "styled-components";
import DynamicInput from "../components/DynamicInput";
import StaticInput from "../components/StaticInput";

const BG = styled.div`
  width: calc(100vw - 4rem);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem 1.5rem;
  padding: 2rem 2rem;
  align-items: center;
  justify-items: start;
`;

const Wrapper = styled.div`
  width: 100%;
  grid-column: 2 / 6;
`;

const Title = styled.div`
  font-size: 2rem;
  padding-bottom: 2rem;
  font-weight: 600;
  /* border-bottom: 2px var(--primary) dashed; */
  width: 100%;
  user-select: none;
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem 1.5rem;
`;

const InfoWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem 1.5rem;
  align-items: center;
  justify-items: start;
  grid-column: 2 / 5;
`;

const Category = styled.div`
  grid-column: 1;
  text-align: right;
  margin-top: 0.5rem;
  border-right: black 2px solid;
  width: calc(100% - 1rem);
  height: calc(100% - 0.5rem);
  padding-right: 1rem;
  font-size: 1.5rem;
  user-select: none;
  transition: 0.2s ease-out;
`;

const ButtonWrapper = styled.div`
  user-select: none;
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  font-family: "Comfortaa", cursive;
`;

const Buttons = styled.button`
  width: 2rem;
  height: 2rem;
  border: none;
  font-size: 1rem;
  margin: 0 0.5rem;
  font-weight: 700;
  background-color: lightgray;
  border-radius: 0.5rem;
  transition: 0.2s ease-out;
  &:hover {
    background-color: var(--secondary);
    color: white;
  }
  &:active {
    background-color: var(--primary);
    color: white;
  }
`;

const ChartTitle = styled.div`
  border-radius: 0.5rem;
  width: 100%;
  background-color: lightgray;
  font-size: 1.25rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  user-select: none;
`;

const SampleCount = styled.div`
  margin: 0.25rem 0.25rem 0;
  width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Request = () => {
  const info = [
    {
      category: "Researcher",
      inputs: [
        { name: "Institute" },
        { name: "PI Name" },
        { name: "Staff Name" },
        { name: "Email" },
        { name: "Phone", isNum: true },
      ],
    },
    {
      category: "Analysis",
      inputs: [{ name: "Specie" }, { name: "Tissue" }, { name: "Type" }],
    },
  ];
  const [sampleCount, setSampleCount] = useState(1);
  return (
    <BG>
      <Wrapper>
        <Title>Request Form</Title>
        <InputWrapper>
          {info.map((el) => {
            return (
              <>
                <Category>{el.category}</Category>
                <InfoWrapper>
                  {el.inputs.map((el) => {
                    return <DynamicInput content={el} />;
                  })}
                </InfoWrapper>
              </>
            );
          })}
          <Category>
            {sampleCount > 1 ? "Samples" : "Sample"}
            <ButtonWrapper>
              <Buttons
                onClick={() => {
                  setSampleCount(sampleCount + 1);
                }}
              >
                +
              </Buttons>
              <SampleCount>{sampleCount}</SampleCount>
              <Buttons
                onClick={() => {
                  if (sampleCount > 1) setSampleCount(sampleCount - 1);
                }}
              >
                -
              </Buttons>
            </ButtonWrapper>
          </Category>
          <InfoWrapper>
            <ChartTitle>Customer ID</ChartTitle>
            <ChartTitle>Geninus ID</ChartTitle>
            <ChartTitle>Group</ChartTitle>
            {[...Array(parseInt(sampleCount))].map((el) => {
              return (
                <>
                  <StaticInput content={{ name: "" }} />
                  <StaticInput content={{ name: "" }} />
                  <StaticInput content={{ name: "" }} />
                </>
              );
            })}
          </InfoWrapper>
        </InputWrapper>
      </Wrapper>
    </BG>
  );
};

export default Request;
