import styled from "styled-components";
import ScatterPlot from "../components/ScatterPlot";

const BG = styled.div`
  height: calc(100vh - 5rem);
  width: calc(100vw - 4rem);
  display: grid;
  background-color: white;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem 1.5rem;
  padding: 0 2rem;
  align-items: center;
  justify-items: start;
  grid-template-areas: ". landing landing landing landing landing landing landing landing landing landing .";
`;

const LandingWrapper = styled.div`
  grid-area: landing;
  width: 100%;
  height: 100%;
`;

const Landing = () => {
  return (
    <BG>
      <LandingWrapper>
        <ScatterPlot></ScatterPlot>
      </LandingWrapper>
    </BG>
  );
};

export default Landing;
