import styled from "styled-components";

const InputWrapper = styled.div`
  position: relative;
  width: ${(props) => props.width || "100%"};
  border-bottom: 1px solid black;
`;

const Indicator = styled.div`
  display: none;
  position: absolute;
  right: 0;
  top: 0.25rem;
  color: gray;
  pointer-events: none;
`;

const InputField = styled.input`
  width: 100%;
  border: 0;
  outline: 0;
  font-size: 1.25rem;
  color: black;
  padding: 0 0 0.25rem;
  background: transparent;
  transition: border-color 0.2s;
  &:focus {
    font-weight: 400;
    & ~ ${InputWrapper} {
      border-bottom: 2px solid red;
    }
    & ~ ${Indicator} {
      display: block;
    }
  }
  &::placeholder {
    font-size: 1.25rem;
    color: gray;
    pointer-events: none;
    font-weight: 400;
    font-family: "Noto Sans KR", sans-serif;
    user-select: none;
  }
  &:required,
  &:invalid {
    box-shadow: none;
  }
`;

const StaticInput = (props) => {
  return (
    <InputWrapper width={props.width}>
      <InputField placeholder={props.content.name} />
      <Indicator>{props.content.isNum ? "123" : "가/Aa"}</Indicator>
    </InputWrapper>
  );
};

export default StaticInput;
